import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import firebase from "../firebase";
import { nanoid } from "nanoid";

const ReferralCodes = () => {
  const [codes, setCodes] = useState<
    {
      id: string;
      application?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    }[]
  >([]);

  const createCodes = useCallback(async () => {
    const batch = firebase.firestore().batch();

    new Array(10).fill(0).forEach((o, i) => {
      batch.set(
        firebase.firestore().collection("referralCodes").doc(nanoid(8)),
        {}
      );
    });

    await batch.commit();
  }, []);

  const deleteCode = useCallback(async (id: string) => {
    try {
      await firebase.firestore().collection("referralCodes").doc(id).delete();
    } catch {}
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("referralCodes")
      .onSnapshot((querySnapshot) => {
        const result = [] as typeof codes;

        querySnapshot.forEach((doc) => {
          result.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setCodes(result);
      });
  }, []);

  return (
    <>
      <div className="is-pulled-right	">
        <button
          className="button"
          onClick={() => {
            if (window.confirm("確定要再新增 10 個推薦碼嗎?")) {
              createCodes();
            }
          }}
        >
          新增 10 個推薦碼
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>推薦碼</th>
            <th>狀態</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {codes.map((code, i) => (
            <tr key={code.id}>
              <th>{i + 1}</th>
              <td>{code.id}</td>
              <td>{code.application ? "已使用" : ""}</td>
              <td>
                {code.application ? null : (
                  <button
                    className="button"
                    onClick={() => {
                      if (window.confirm("確定要刪除嗎?")) {
                        deleteCode(code.id);
                      }
                    }}
                  >
                    刪除
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ReferralCodes;
