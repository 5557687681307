import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FiSettings, FiSlash, FiX } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useSelectedChatroom } from "../../redux/slices/chatroom";
import chatroomSlice from "../../redux/slices/chatroom";
import { useAuth } from "../../redux/slices/auth";
import { fromNow } from "../../helpers/time";
import firebase from "../../firebase";
import { Link } from "react-router-dom";
import NavItem from "../navigation/NavItem";

const Chat = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const chatroom = useSelectedChatroom();
  const [text, setText] = useState("");
  const chatEl = useRef<HTMLDivElement | null>(null);
  const listEl = useRef<HTMLDivElement | null>(null);
  const scrollBottom = useRef(0);

  const [scolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    if (chatEl.current && chatroom?.messages?.length && !scolledToBottom) {
      chatEl.current.scrollIntoView({ behavior: "auto" });
      setScrolledToBottom(true);
    }
  }, [scolledToBottom, chatroom?.messages?.length]);

  useEffect(() => {
    var e = listEl.current;

    if (!e) {
      return;
    }

    e.scrollTop = e.scrollHeight - e.offsetHeight - scrollBottom.current;
  }, [chatroom?.messages]);

  const leaveChatroom = useCallback(() => {
    if (chatroom?.id && auth.user?.id) {
      firebase
        .firestore()
        .collection("chatrooms")
        .doc(chatroom.id)
        .update({
          [`readedAt.${auth.user.id}`]: Date.now(),
        });
    }

    dispatch(chatroomSlice.actions.leave());
  }, [dispatch, auth.user?.id, chatroom?.id]);

  const sendMessage = useCallback(() => {
    const userId = auth.user?.id;

    if (text.trim() === "" || !userId || !chatroom) {
      return;
    }

    const id = Date.now().toString();
    const message = {
      id,
      userId,
      content: text.trim(),
    };

    setText("");

    firebase
      .firestore()
      .collection("chatrooms")
      .doc(chatroom.id)
      .collection("messages")
      .doc(id)
      .set(message)
      .then(() => {});
  }, [auth.user?.id, text, chatroom]);

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    },
    [sendMessage]
  );

  const loadMore = useCallback(
    (chatroomId: string, afterId: string) => {
      firebase
        .firestore()
        .collection("chatrooms")
        .doc(chatroomId)
        .collection("messages")
        // .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
        .orderBy("id", "desc")
        .limit(100)
        .startAfter(afterId)
        .get()
        .then((result) => {
          const changes = result.docChanges();
          dispatch(
            chatroomSlice.actions.updateMessages({
              chatroomId,
              changes,
            })
          );
        });
    },
    [dispatch]
  );

  if (!chatroom) {
    return null;
  }

  const toUserId = Object.keys(chatroom.userIds).find(
    (id) => !!auth.user && id !== auth.user.id
  );

  const toUser = chatroom.users?.find(
    (user) => !!toUserId && user.id === toUserId
  );

  return (
    <div
      className={classNames("chat-wrapper", {
        "is-active": !!chatroom,
      })}
    >
      <div className="chat-inner">
        <div className="chat-nav">
          <div className="nav-start">
            <div className="recipient-block">
              <div className="avatar-container">
                {!!toUser?.photos && toUser.photos.length > 0 && (
                  <Link to={`/users/${toUser.id}`}>
                    <img
                      className="user-avatar"
                      src={toUser.photos[0].url}
                      alt=""
                    />
                  </Link>
                )}
              </div>
              <div className="username">
                <span>{toUser?.profile?.nickname}</span>
                <span>{toUser?.connected?.value ? "上線中" : "離線"}</span>
              </div>
            </div>
            <NavItem
              renderIcon={() => <FiSettings />}
              dropdownClassNames="mr-2"
            >
              <div className="inner is-unselectable">
                <div className="nav-drop-header">
                  <span className="username">設定</span>
                </div>

                <div className="nav-drop-body account-items">
                  <div
                    className="account-item"
                    onClick={async () => {
                      if (
                        auth.user?.id &&
                        window.confirm("確定要將對方加入你的封鎖名單嗎?")
                      ) {
                        try {
                          await firebase
                            .firestore()
                            .collection("users")
                            .doc(auth.user.id)
                            .update({
                              blackList:
                                firebase.firestore.FieldValue.arrayUnion(
                                  toUser?.id
                                ),
                            });
                          leaveChatroom();
                        } catch {}
                      }
                    }}
                  >
                    <div className="media">
                      <div className="icon-wrap">
                        <FiSlash />
                      </div>
                      <div className="media-content">
                        <h3>黑名單</h3>
                        <small>將使用者加入黑名單</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavItem>
          </div>
          <div className="nav-end">
            <div
              className="chat-nav-item is-icon close-chat is-clickable"
              onClick={leaveChatroom}
            >
              <FiX />
            </div>
          </div>
        </div>

        <div id="chat-body" className="chat-body is-opened">
          <div
            ref={listEl}
            className="chat-body-inner has-slimscroll"
            onScroll={(e) => {
              const target = e.target as HTMLDivElement;

              const value =
                target.scrollHeight - target.scrollTop - target.offsetHeight;
              scrollBottom.current = value;

              if (target.scrollTop === 0) {
                //fetch messages
                if (chatroom.id && chatroom.messages) {
                  loadMore(chatroom.id, chatroom.messages[0].id);
                }
              }
            }}
          >
            {/* <div className="date-divider">
              <hr className="date-divider-line" />
              <span className="date-divider-text">Today</span>
            </div> */}

            {chatroom.messages
              ? chatroom.messages.map((message) => {
                  const isSent = message.userId === auth.user?.id;
                  const photos = isSent ? auth.user?.photos : toUser?.photos;
                  const avatar = photos ? photos[0].url : null;

                  return (
                    <div
                      key={message.id}
                      className={classNames("chat-message", {
                        "is-received": !isSent,
                        "is-sent": isSent,
                      })}
                    >
                      {!!avatar && <img src={avatar} alt="" />}

                      <div className="message-block">
                        <div className="message-text">{message.content}</div>
                        <span>{fromNow(+message.id)}</span>
                      </div>
                    </div>
                  );
                })
              : null}
            <div ref={chatEl}></div>
          </div>
          <div className="chat-textarea-container">
            <div className="field is-grouped">
              <div className="control is-expanded">
                <textarea
                  rows={1}
                  className="textarea"
                  onKeyPress={onKeyPress}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />{" "}
              </div>
              <div className="control">
                <div className="button is-info" onClick={sendMessage}>
                  {" "}
                  發送
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
