import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
require("dayjs/locale/zh-tw");

dayjs.extend(relativeTime);

export const fromNow = (timestamp: number) => {
  // dayjs.duration(1, "minutes").locale("es").humanize();
  return dayjs(timestamp).locale("zh-tw").fromNow();
};
