import React, { useCallback, useEffect } from "react";
import { FiMessageSquare } from "react-icons/fi";
import { useDispatch } from "react-redux";
import firebase from "../../firebase";
import { fromNow } from "../../helpers/time";
import { useAuth } from "../../redux/slices/auth";
import chatroomSlice, { useChatroom } from "../../redux/slices/chatroom";
import { Chatroom, User } from "../../types";

const Row = ({ chatroom }: { chatroom: Chatroom }) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const toUserId = Object.keys(chatroom.userIds).find(
    (id) => !!auth.user && id !== auth.user.id
  );

  const toUser = chatroom.users?.find(
    (user) => !!toUserId && user.id === toUserId
  );

  useEffect(() => {
    if (toUserId) {
      return firebase
        .firestore()
        .collection("users")
        .doc(toUserId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const user = {
              id: snapshot.id,
              ...snapshot.data(),
            } as User;

            dispatch(
              chatroomSlice.actions.setUser({
                chatroomId: chatroom.id,
                user,
              })
            );
          }
        });
    }
  }, [dispatch, chatroom.id, toUserId]);

  useEffect(() => {
    return (
      firebase
        .firestore()
        .collection("chatrooms")
        .doc(chatroom.id)
        .collection("messages")
        // .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
        .orderBy("id", "desc")
        .limit(10)
        .onSnapshot((snapshot) => {
          const changes = snapshot.docChanges();
          dispatch(
            chatroomSlice.actions.updateMessages({
              chatroomId: chatroom.id,
              changes,
            })
          );
        })
    );
  }, [dispatch, chatroom.id]);

  const enterChatroom = useCallback(() => {
    dispatch(chatroomSlice.actions.enter(chatroom.id));
  }, [dispatch, chatroom.id]);

  const lastMessage = chatroom.messages
    ? chatroom.messages[chatroom.messages.length - 1]
    : null;

  return toUser ? (
    <div className="media cursor-pointer" onClick={enterChatroom}>
      <figure className="media-left">
        <p className="image">
          {!!toUser.photos && toUser.photos.length > 0 && (
            <img src={toUser.photos[0].url} alt="" />
          )}
        </p>
      </figure>
      <div className="media-content">
        <span className="mb-1">{toUser.profile?.nickname}</span>
        <span>{lastMessage ? lastMessage.content : ""}</span>
        {!!lastMessage && (
          <span className="time">{fromNow(+lastMessage.id)}</span>
        )}
        {chatroom.isNew && (
          <span className="has-text-info">可以開始與對方聊天了</span>
        )}
      </div>
      <div className="media-right is-centered">
        {chatroom.hasUnread ? (
          <span className="is-size-7 has-text-link-dark	">有新訊息</span>
        ) : (
          <div className="added-icon">
            <FiMessageSquare />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const ChatroomList = () => {
  const { list } = useChatroom();

  return (
    <div className="inner is-unselectable">
      <div className="nav-drop-header">
        <span>訊息</span>
      </div>
      <div className="nav-drop-body is-friend-requests">
        {list.map((item) => (
          <Row key={item.id} chatroom={item} />
        ))}
        {list.length === 0 && (
          <div className="media">
            <div className="media-content">
              <span>無</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatroomList;
