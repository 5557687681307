/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import Logo from "../../assets/img/logo/logo.png";
import {
  FiMessageSquare,
  FiSettings,
  FiPower,
  FiUser,
  FiKey,
} from "react-icons/fi";
import NavItem from "./NavItem";
import { User } from "../../types";
import ChatroomList from "../chat/ChatroomList";
import firebase from "../../firebase";
import { Link } from "react-router-dom";
import Chat from "../chat/Chat";
import { convertVerifyStatus } from "../../helpers/text";
import { useChatroom } from "../../redux/slices/chatroom";

type Props = {
  authUser: User;
};

const NavBar = ({ authUser }: Props) => {
  const verifyStatus = convertVerifyStatus(authUser.application?.verifyStatus);
  const { list } = useChatroom();

  return (
    <>
      <nav className="navbar mobile-navbar" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img className="logo light-image" src={Logo} alt="" />
          </Link>

          {verifyStatus === "已通過" && !!authUser.profile && (
            <NavItem
              renderIcon={() => <FiMessageSquare />}
              showIndicator={
                !!list.find((item) => item.isNew || item.hasUnread)
              }
            >
              <ChatroomList />
            </NavItem>
          )}

          <div style={{ marginLeft: "auto" }}>
            <NavItem
              renderIcon={() => <FiUser />}
              dropdownClassNames="is-account-dropdown mr-2"
            >
              <div className="inner is-unselectable">
                {!!authUser.profile?.nickname && (
                  <div className="nav-drop-header">
                    <span className="username">
                      {authUser.profile?.nickname}
                    </span>
                  </div>
                )}

                <div className="nav-drop-body account-items">
                  {authUser?.role === "admin" && (
                    <Link to="/admin" className="account-item">
                      <div className="media">
                        <div className="icon-wrap">
                          <FiKey />
                        </div>
                        <div className="media-content">
                          <h3>後台</h3>
                          <small>(管理員)</small>
                        </div>
                      </div>
                    </Link>
                  )}
                  {verifyStatus === "已通過" && (
                    <Link to="/profile" className="account-item">
                      <div className="media">
                        <div className="icon-wrap">
                          <FiSettings />
                        </div>
                        <div className="media-content">
                          <h3>設定</h3>
                          <small>關於你的帳號設定</small>
                        </div>
                      </div>
                    </Link>
                  )}
                  <div
                    className="account-item"
                    onClick={() => firebase.auth().signOut()}
                  >
                    <div className="media">
                      <div className="icon-wrap">
                        <FiPower />
                      </div>
                      <div className="media-content">
                        <h3>登出</h3>
                        <small>從你的帳號登出</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavItem>
          </div>
        </div>
      </nav>
      <Chat />
    </>
  );
};

export default NavBar;
