import { useCallback, useState } from "react";
import firebase, { deleteImage, uploadImage } from "../firebase";
import { checkAuth } from "../helpers/auth";
import { convertVerifyStatus } from "../helpers/text";
import UploadImageModal from "../partials/modal/UploadImageModal";
import { User } from "../types";

type Props = {
  authUser: User;
};

const ApplyForm = ({ authUser }: Props) => {
  const [referralCode, setReferralCode] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);

  const submitReferralCode = useCallback(
    async (code: string) => {
      setVerifying(true);
      try {
        const doc = await firebase
          .firestore()
          .collection("referralCodes")
          .doc(code)
          .get();

        if (doc.exists) {
          const data = doc.data();
          if (data && !data.user) {
            firebase
              .firestore()
              .collection("users")
              .doc(authUser.id)
              .update({
                "application.referralCode": code,
                "application.randomNumber": Math.floor(Math.random() * 5 + 1),
              });
            firebase
              .firestore()
              .collection("referralCodes")
              .doc(code)
              .set({
                user: firebase.firestore().doc(`users/${authUser.id}`),
              });
          } else {
            alert("無法使用此推薦碼");
          }
        } else {
          alert("推薦碼錯誤");
        }
      } catch {}
      setVerifying(false);
    },
    [authUser.id]
  );

  const { application } = authUser;

  const verifyStatus = convertVerifyStatus(application?.verifyStatus);

  const myPhoto = application?.myPhoto;

  const editDisabled = verifyStatus === "審核中" || verifyStatus === "已通過";

  return (
    <>
      <div className="question-content is-large mt-5">
        {(() => {
          let info = "";
          switch (verifyStatus) {
            case "已通過":
              info = "已通過審核，可以開始使用了";
              break;
            case "審核中":
              info = "審核中，管理員會盡快為你審核";
              break;
            case "已拒絕":
              info = "未通過審核，請修改資料並重新提交";
              break;
          }

          return info ? <div className="notification">{info}</div> : null;
        })()}

        <div className="columns true-dom">
          <div className="column">
            <div className="categories-header">
              <h2>身份驗證</h2>
              <div className="control">
                <button
                  className="button is-solid accent-button form-button"
                  disabled={editDisabled}
                  onClick={async () => {
                    if (!application?.myPhoto) {
                      return alert("請新增照片");
                    }

                    if (window.confirm("提交後管理員會為你進行審核，確定嗎")) {
                      await firebase
                        .firestore()
                        .collection("users")
                        .doc(authUser.id)
                        .update({
                          "application.verifyStatus": 0,
                        });
                    }
                  }}
                >
                  {(() => {
                    switch (verifyStatus) {
                      case "未定義":
                      case "已拒絕":
                        return "提交";
                      default:
                        return verifyStatus;
                    }
                  })()}
                </button>
              </div>
            </div>

            {application?.referralCode ? (
              <div>已使用推薦碼: {application.referralCode}</div>
            ) : (
              <div className="field has-addons">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="輸入推薦碼"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                  />
                </div>
                <div className="control">
                  <button
                    className={`button is-info ${
                      verifying ? "is-loading" : ""
                    }`}
                    onClick={() => submitReferralCode(referralCode)}
                    disabled={editDisabled}
                  >
                    使用
                  </button>
                </div>
              </div>
            )}

            <hr />

            <div>
              <p className="mb-2">
                {application?.referralCode
                  ? `請提供自拍照(請用手比${application.randomNumber})`
                  : "請提供自拍照(證件與本人之合照)"}
              </p>
              <button
                className="button is-solid mb-2"
                onClick={() => setModal1Visible(true)}
                disabled={editDisabled}
              >
                選擇照片
              </button>
              <UploadImageModal
                visible={modal1Visible}
                onRequestClose={() => setModal1Visible(false)}
                onUploadImage={async (image) => {
                  const item = await uploadImage(
                    image,
                    `users/${authUser.id}/applications/me`
                  );
                  await firebase
                    .firestore()
                    .collection("users")
                    .doc(authUser.id)
                    .update({
                      "application.myPhoto": item,
                    });
                }}
              />

              {myPhoto ? (
                <div className="columns is-multiline">
                  <div className="column is-4">
                    <div className="card post-feed-item">
                      <div className="card-image">
                        <figure className="image is-4by3">
                          <img src={myPhoto.url} alt="me" />
                        </figure>
                      </div>
                      <button
                        className="button is-solid is-fullwidth mt-2"
                        onClick={async () => {
                          await firebase
                            .firestore()
                            .collection("users")
                            .doc(authUser.id)
                            .update({
                              "application.myPhoto":
                                firebase.firestore.FieldValue.delete(),
                            });
                          await deleteImage(myPhoto.path);
                        }}
                        disabled={editDisabled}
                      >
                        刪除
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <hr />

            <div>
              <p>專業人士可取得認證標章(可選)</p>
              <p className="mb-2">
                [溫馨提醒]
                如果您是醫師，請務必使用醫師執照，才能獲得認證標章喔！最多可傳三張照片
              </p>
              <div className="mb-2">
                <button
                  disabled={
                    (application?.licensePhotos ?? []).length >= 3 ||
                    editDisabled
                  }
                  className="button is-solid"
                  onClick={() => setModal2Visible(true)}
                >
                  選擇照片
                </button>
                <UploadImageModal
                  visible={modal2Visible}
                  onRequestClose={() => setModal2Visible(false)}
                  onUploadImage={async (image) => {
                    const item = await uploadImage(
                      image,
                      `users/${authUser.id}/applications/${
                        (application?.licensePhotos?.length ?? 0) + 1
                      }`
                    );
                    await firebase
                      .firestore()
                      .collection("users")
                      .doc(authUser.id)
                      .update({
                        "application.licensePhotos":
                          firebase.firestore.FieldValue.arrayUnion(item),
                      });
                  }}
                />
              </div>

              <div className="columns is-multiline">
                {(application?.licensePhotos ?? []).map((item) => {
                  return (
                    <div key={item.url} className="column is-4">
                      <div className="card post-feed-item">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img src={item.url} alt="me" />
                          </figure>
                        </div>
                        <button
                          className="button is-solid is-fullwidth mt-2"
                          onClick={async () => {
                            await firebase
                              .firestore()
                              .collection("users")
                              .doc(authUser.id)
                              .update({
                                "application.licensePhotos":
                                  firebase.firestore.FieldValue.arrayRemove(
                                    item
                                  ),
                              });
                            await deleteImage(item.path);
                          }}
                          disabled={editDisabled}
                        >
                          刪除
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default checkAuth(ApplyForm);
