import React from "react";

const SubLoader = () => {
  return (
    <div className="subloader is-active">
      <div className="pageloader is-active"></div>;
      {/* <div className="loader is-loading"></div> */}
    </div>
  );
};

export default SubLoader;
