import { useCallback, useEffect, useState } from "react";

const useGeoLocation = () => {
  const [location, setLocation] = useState<{
    loaded: boolean;
    position: GeolocationPosition | null;
    error: any;
  }>({
    loaded: false,
    position: null,
    error: null,
  });

  const onSuccess = (position: GeolocationPosition) => {
    setLocation({
      loaded: true,
      position,
      error: null,
    });
  };

  const onError = useCallback((error: any) => {
    setLocation((prev) => ({
      ...prev,
      loaded: true,
      coordinates: null,
      error,
    }));
  }, []);

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
      return;
    }

    navigator.geolocation.watchPosition(onSuccess, onError);
  }, [onError]);

  return location;
};

export default useGeoLocation;
