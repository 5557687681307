import { Redirect, useLocation } from "react-router-dom";
import PageLoader from "../partials/pageloader/PageLoader";
import { useAuth } from "../redux/slices/auth";
import { User } from "../types";

export const checkAuth =
  (Component: React.FunctionComponent<{ authUser: User }>) => () => {
    const location = useLocation();
    const auth = useAuth();

    if (!auth.isResolved) {
      return <PageLoader />;
    } else if (!auth.user) {
      return <Redirect to="/login" />;
    } else if (auth.user.application?.verifyStatus !== 1) {
      if (location.pathname !== "/apply") {
        return <Redirect to="/apply" />;
      }
    } else if (
      !auth.user.profile ||
      !auth.user.photos ||
      auth.user.photos.length === 0
    ) {
      if (location.pathname !== "/profile") {
        return <Redirect to="/profile" />;
      }
    }

    return <Component authUser={auth.user} />;
  };

export const checkAdmin =
  (Component: React.FunctionComponent<{ authUser: User }>) => () => {
    const auth = useAuth();

    if (!auth.isResolved) {
      return <PageLoader />;
    }

    return auth.user?.role === "admin" ? (
      <Component authUser={auth.user} />
    ) : (
      <Redirect to="/" />
    );
  };
