import React from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Applications from "../admin-pages/Applications";
import ReferralCodes from "../admin-pages/ReferralCodes";
import VerifyApplication from "../admin-pages/VerifyApplication";
// import FakeAccounts from "../admin-pages/FakeAccounts";
import { checkAdmin } from "../helpers/auth";
import NavBar from "../partials/navigation/NavBar";
import { User } from "../types";

type Props = {
  authUser: User;
};

const Admin = ({ authUser }: Props) => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <NavBar authUser={authUser} />
      <div className="view-wrapper">
        <div className="container">
          <div className="tabs" id="admin-tabs">
            <ul>
              <li>
                <NavLink exact to={`${url}`} activeClassName="is-active">
                  待審核
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${url}/referral-codes`}
                  activeClassName="is-active"
                >
                  推薦碼
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to={`${url}/fake-accounts`}
                  activeClassName="is-active"
                >
                  測試帳號
                </NavLink>
              </li> */}
            </ul>
          </div>

          <div className="container">
            <Switch>
              <Route exact path={path}>
                <Applications />
              </Route>
              <Route path={`${path}/applications/:id`}>
                <VerifyApplication />
              </Route>
              <Route path={`${path}/referral-codes`}>
                <ReferralCodes />
              </Route>
              {/* <Route path={`${path}/fake-accounts`}>
                <FakeAccounts authUser={authUser} />
              </Route> */}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default checkAdmin(Admin);
