import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import { User } from "../types";
import { Link } from "react-router-dom";
import { convertVerifyStatus } from "../helpers/text";

const Applications = () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection("users")
      .where("application.verifyStatus", "==", 0) // pending
      .onSnapshot((querySnapshot) => {
        const result = [] as typeof users;

        querySnapshot.forEach((doc) => {
          result.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setUsers(result);
      });
  }, []);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>狀態</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {users
            .filter((user) => !user.isFake)
            .map((user, i) => (
              <tr key={user.id}>
                <th>{i + 1}</th>
                <td>{convertVerifyStatus(user.application?.verifyStatus)}</td>
                <td>
                  <Link
                    to={`/admin/applications/${user.id}`}
                    className="button"
                  >
                    編輯
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Applications;
