import React from "react";
import { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SubLoader from "../partials/pageloader/SubLoader";
import firebase from "../firebase";
import { User } from "../types";
import { convertVerifyStatus } from "../helpers/text";

const VerifyApplication = () => {
  const params = useParams<{ id: string }>();
  const uid = params.id;

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<null | User>(null);

  const updateStatus = useCallback(
    async (verifyStatus: -1 | 1) => {
      if (window.confirm("確定要修改狀態嗎?")) {
        await firebase.firestore().collection("users").doc(uid).update({
          "application.verifyStatus": verifyStatus,
        });
      }
    },
    [uid]
  );

  useEffect(() => {
    if (!uid) return;

    setLoading(true);

    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot((snapshot) => {
        setUser(
          snapshot.exists
            ? ({
                id: snapshot.id,
                ...snapshot.data(),
              } as User)
            : null
        );
        setLoading(false);
      });
  }, [uid]);

  if (loading) return <SubLoader />;

  if (!user) return <div>找不到此使用者的資料</div>;

  const verifyStatus = convertVerifyStatus(user?.application?.verifyStatus);

  return (
    <div className="container px-4 py-4">
      <div className="is-pulled-right	">
        {(() => {
          if (verifyStatus === "審核中") {
            return (
              <>
                <button className="button mr-2" onClick={() => updateStatus(1)}>
                  通過
                </button>

                <button className="button" onClick={() => updateStatus(-1)}>
                  拒絕
                </button>
              </>
            );
          } else if (verifyStatus === "已通過") {
            return (
              <button className="button" disabled>
                已通過
              </button>
            );
          } else if (verifyStatus === "已拒絕") {
            return (
              <button className="button" disabled>
                已拒絕
              </button>
            );
          }
          return <div>等待使用者提出申請</div>;
        })()}
      </div>

      {user.application?.referralCode ? (
        <div>已使用推薦碼 {user.application.referralCode}</div>
      ) : (
        <div>未使用推薦碼</div>
      )}
      <hr />

      <div>
        <div>
          {user.application?.referralCode
            ? `自拍照(用手比${user.application.randomNumber})`
            : "自拍照(證件與本人之合照)"}
        </div>

        {user.application?.myPhoto ? (
          <img
            src={user.application.myPhoto.url}
            alt="me"
            style={{ width: 400 }}
          />
        ) : (
          <div className="notification">無照片</div>
        )}
      </div>
      <hr />

      <div>
        <div>專業證件</div>
        {(user.application?.licensePhotos ?? []).map((item) => {
          return (
            <div key={item.url}>
              <img src={item.url} alt="license" style={{ width: 400 }} />
            </div>
          );
        })}
        {(user.application?.licensePhotos?.length ?? 0) === 0 && (
          <div className="notification">無照片</div>
        )}
      </div>
    </div>
  );
};

export default VerifyApplication;
