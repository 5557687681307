import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { FiCamera, FiUpload, FiX } from "react-icons/fi";
import { GiPlainCircle } from "react-icons/gi";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../helpers/cropImage";
import Webcam from "react-webcam";

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  onUploadImage: (image: string) => Promise<void>;
  aspect?: number;
};

const UploadImageModal = ({
  visible,
  onRequestClose,
  onUploadImage,
  aspect,
}: Props) => {
  const [preview, setPreview] = useState<null | string>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPreview(null);
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 3 * 1024 * 1024) {
          return alert("檔案太大，請上傳 3 MB以下的圖片");
        }

        const image = URL.createObjectURL(file);
        setPreview(image);
      }
    } else {
      return alert("請選擇檔案");
    }
  }, []);

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleClose = useCallback(() => {
    setPreview(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setWebcamVisible(false);
    onRequestClose();
  }, [onRequestClose]);

  const onClickUpload = useCallback(
    async (image: string) => {
      setLoading(true);

      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);

        await onUploadImage(croppedImage as string);
      } catch {
        alert("發生錯誤");
      }

      setLoading(false);

      handleClose();
    },
    [handleClose, croppedAreaPixels, onUploadImage]
  );

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setPreview(imageSrc);
      setWebcamVisible(false);
    }
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div>
      <div className="modal upload-image-modal is-medium has-light-bg is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          {webcamVisible ? (
            <div className="card">
              <div className="card-heading">
                <h3>拍攝照片</h3>
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => setWebcamVisible(false)}
                  >
                    <FiX />
                  </span>
                </div>
              </div>
              <div className="has-text-centered	py-4">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={400}
                  // height={"100VH"}

                  // videoConstraints={{
                  //   width: 400,
                  //   height: 700,
                  //   facingMode: "user",
                  // }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: 150,
                  }}
                >
                  <GiPlainCircle
                    size={60}
                    style={{
                      margin: "auto",
                      color: "#333",
                      border: "6px solid #fff",
                      borderRadius: "50%",
                    }}
                    onClick={capture}
                  />
                </div>
              </div>
            </div>
          ) : !!preview ? (
            <div className="card">
              <div className="card-heading">
                <h3>調整照片</h3>
                <div className="close-wrap">
                  <span className="close-modal" onClick={handleClose}>
                    <FiX />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div style={{ position: "relative", height: "60VH" }}>
                  <Cropper
                    image={preview}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
                <button
                  className={classNames(
                    "button is-solid accent-button is-fullwidth raised mt-4",
                    {
                      "is-loading": loading,
                    }
                  )}
                  onClick={() => onClickUpload(preview)}
                >
                  上傳照片
                </button>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-heading">
                <h3>上傳照片</h3>
                <div className="close-wrap">
                  <span className="close-modal" onClick={onRequestClose}>
                    <FiX />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="selection-placeholder">
                  <div className="columns">
                    <div className="column is-6">
                      <label className="file-label" htmlFor="upload-photo">
                        <div
                          className="selection-box modal-trigger"
                          data-modal="user-photos-modal"
                        >
                          <div className="box-content cursor-pointer">
                            <FiUpload size={40} color="#333" />
                            <div className="box-text">
                              <span className="is-size-6 has-text-weight-bold	mt-2">
                                選擇
                              </span>
                              <span>請從裝置選擇照片</span>
                            </div>
                            <input
                              id="upload-photo"
                              className="file-input"
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={handleChange}
                              style={{
                                display: "none",
                              }}
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="column is-6">
                      <div
                        className="selection-box modal-trigger"
                        data-modal="user-photos-modal"
                        onClick={() => setWebcamVisible(true)}
                      >
                        <div className="box-content">
                          <FiCamera size={40} color="#333" />
                          <div className="box-text">
                            <span className="is-size-6 has-text-weight-bold	">
                              拍照
                            </span>
                            <span>使用相機拍攝照片</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadImageModal;
