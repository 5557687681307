import React, { useCallback, useEffect, useRef, useState } from "react";
import TinderCard from "../../components/TinderCard";
import firebase from "../../firebase";
import { User } from "../../types";
import "./TinderCards.scss";
import SubLoader from "../pageloader/SubLoader";
import { FiHeart, FiX, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import dayjs from "dayjs";
import { convertGender } from "../../helpers/text";
import { calculateDistance } from "../../helpers/distance";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const UserCard = ({
  authUser,
  user,
}: {
  authUser: User | null;
  user: User;
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { width } = useWindowDimensions();

  const renderProfile = () => {
    const age = dayjs().diff(user.profile?.birthday, "year");
    const gender = convertGender(user.profile?.gender);
    const distance =
      authUser && authUser.position && user.position
        ? calculateDistance(
            authUser.position.latitude,
            authUser.position.longitude,
            user.position.latitude,
            user.position.longitude
          )
        : null;

    return (
      <div>
        <span className="is-size-4 has-text-weight-bold">
          {user.profile?.nickname}
        </span>
        {age > 0 && <span className="ml-2 is-size-5">{age}</span>}
        {!!distance && <div className="my-1">{distance}公里</div>}
        {gender !== "" && <div className="my-1">{gender}</div>}

        <div>{user.profile?.description}</div>
      </div>
    );
  };

  const renderImages = () => {
    return (
      <div
        className="image-container cursor-pointer"
        style={{ position: "relative" }}
      >
        {!!selectedPhoto && (
          <div
            className="card-image"
            style={{
              backgroundImage: `url(${selectedPhoto})`,
              backgroundPosition: "50% 50%",
            }}
            onClick={(e) => {
              if (e.pageX < width / 2) {
                if (visiblePhotoIndex > 0) {
                  setVisiblePhotoIndex(visiblePhotoIndex - 1);
                }
              } else {
                if (visiblePhotoIndex < photos.length - 1) {
                  setVisiblePhotoIndex(visiblePhotoIndex + 1);
                }
              }
            }}
          >
            {photos.length > 1 && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 12,
                    left: 12,
                    right: 12,
                    height: 16,
                    display: "flex",
                  }}
                >
                  {photos.map((_, i) => (
                    <div
                      key={i}
                      style={{
                        flex: 1,
                        marginLeft: 2,
                        marginRight: 2,
                        height: 4,
                        backgroundColor:
                          i === visiblePhotoIndex ? "#fff" : "#888",
                        borderRadius: 4,
                      }}
                    />
                  ))}
                </div>
                {visiblePhotoIndex > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: 8,
                      display: "flex",
                    }}
                  >
                    <FiChevronLeft color="#fff" size={60} />
                  </div>
                )}
                {visiblePhotoIndex < photos.length - 1 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 8,
                      display: "flex",
                    }}
                  >
                    <FiChevronRight color="#fff" size={60} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const photos = (user.photos ?? []).map((item) => item.url);

  const [visiblePhotoIndex, setVisiblePhotoIndex] = useState(0);

  const selectedPhoto = photos.find((item, i) => i === visiblePhotoIndex);

  return selectedUser ? (
    <div className="card user-card detail">
      {renderImages()}
      <div className="user-profile">
        <div
          id="tinder-card-cancel-button"
          className="cursor-pointer is-pulled-right has-text-link"
          onClick={() => setSelectedUser(null)}
        >
          返回
        </div>
        {renderProfile()}
      </div>
    </div>
  ) : (
    <div className="card user-card">
      {renderImages()}
      <div className="user-profile">
        <div
          id="tinder-card-more-button"
          className="cursor-pointer is-pulled-right has-text-link "
          onClick={() => {
            setSelectedUser(user);
          }}
        >
          更多
        </div>
        {renderProfile()}
      </div>
    </div>
  );
};

type Props = {
  authUser: User;
};

const TinderCards = ({ authUser }: Props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const removedUserIdsRef = useRef<string[]>([]);
  const lastUserRef = useRef<any>(null);
  const childRefs = useRef<{ [key: string]: any }>({});

  const fetchUsers = useCallback(
    ({
      userId,
      gender,
      preferGender,
    }: {
      userId: string;
      gender: number;
      preferGender: number | undefined;
    }) => {
      setLoading(true);

      let query = firebase
        .firestore()
        .collection("users")
        .where(firebase.firestore.FieldPath.documentId(), "!=", userId)
        .where(
          "profile.gender",
          "in",
          [0, 1, 2].filter((n) => {
            if (preferGender !== undefined) {
              return preferGender === 2 ? true : n === preferGender;
            } else {
              return gender === 2 || n !== gender;
            }
          })
        );

      query = query
        .limit(100)
        .orderBy(firebase.firestore.FieldPath.documentId());

      if (lastUserRef.current) {
        query = query.startAfter(lastUserRef.current);
      }

      query
        .get()
        .then((result) => {
          lastUserRef.current = result.docs[result.docs.length - 1];

          const userList = result.docs.map((item) => ({
            id: item.id,
            ...item.data(),
          })) as User[];

          removedUserIdsRef.current = [];
          setUsers(userList);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    lastUserRef.current = null;
    fetchUsers({
      userId: authUser.id,
      gender: authUser.profile?.gender ?? 0,
      preferGender: authUser.profile?.preferGender,
    });
  }, [
    fetchUsers,
    authUser.id,
    authUser.profile?.gender,
    authUser.profile?.preferGender,
  ]);

  const likeUser = useCallback(
    async (userId: string) => {
      try {
        await firebase
          .firestore()
          .collection("users")
          .doc(authUser.id)
          .update({
            likes: firebase.firestore.FieldValue.arrayUnion(userId),
          });
      } catch {}
    },
    [authUser.id]
  );

  const availableUsers = users.filter((user) => {
    // !authUser.likes?.includes(user.id) &&
    return (
      !!user.photos &&
      !removedUserIdsRef.current.includes(user.id) &&
      (process.env.NODE_ENV === "production" ? !user.isFake : true)
    );
  });

  const refresh = (authUser: User) => {
    fetchUsers({
      userId: authUser.id,
      gender: authUser.profile?.gender ?? 0,
      preferGender: authUser.profile?.preferGender,
    });
  };

  const onClickButtonToSwipe = (dir: "left" | "right") => {
    let topUserId = "";

    for (let i = availableUsers.length - 1; i >= 0; i--) {
      const userId = availableUsers[i].id;
      if (!removedUserIdsRef.current.includes(userId)) {
        topUserId = userId;
        break;
      }
    }
    if (topUserId && childRefs.current[topUserId]) {
      childRefs.current[topUserId].swipe(dir);
    }
  };

  if (loading) {
    return <SubLoader />;
  }

  return (
    <div className="tinderCards__cardContainer">
      {availableUsers.map((user, i) => (
        <TinderCard
          // @ts-ignore
          className="swipe tinder-card"
          // @ts-ignore
          ref={(ref) => (childRefs.current[user.id] = ref)}
          key={user.id}
          preventSwipe={[]}
          // flickOnSwipe={false}
          // preventSwipe={["up", "down"]}
          onSwipe={(dir) => {
            removedUserIdsRef.current = [...removedUserIdsRef.current, user.id];

            if (i === 0) {
              refresh(authUser);
            }

            if (dir === "right" || dir === "up") {
              likeUser(user.id);
            }
          }}
          onCardLeftScreen={(dir) => {}}
        >
          <div id={user.id}>
            <UserCard user={user} authUser={authUser} />
          </div>
        </TinderCard>
      ))}
      {!!availableUsers.length && (
        <div className="action-buttons">
          <FiX
            size={60}
            className="icon-button ignore cursor-pointer"
            onClick={() => onClickButtonToSwipe("left")}
          />
          <FiHeart
            size={56}
            className="icon-button like cursor-pointer"
            onClick={() => onClickButtonToSwipe("right")}
          />
        </div>
      )}
      {!loading && availableUsers.length === 0 ? (
        <div className="has-text-centered">
          <p>目前沒有更多人選</p>
          <button className="button mt-4" onClick={() => refresh(authUser)}>
            重新整理
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default TinderCards;
