import React, { useEffect } from "react";
import firebase, {
  updateUserConnectedState,
  watchUser,
  watchChatrooms,
  updateUserPosition,
} from "./firebase";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import Login from "./pages/Login";
import authSlice, { useAuth } from "./redux/slices/auth";
import chatroomSlice, { useChatroom } from "./redux/slices/chatroom";

import { useDispatch } from "react-redux";
import Admin from "./pages/Admin";
import NavBar from "./partials/navigation/NavBar";
import Profile from "./pages/Profile";
import ApplyForm from "./pages/ApplyForm";
import { checkAuth } from "./helpers/auth";
import Signup from "./pages/Signup";
import Help from "./pages/Help";
import { toast } from "react-toastify";
import UserPreviewModal from "./partials/modal/UserPreviewModal";
import useGeoLocation from "./hooks/useGeoLocation";
import EmailLogin from "./pages/EmailLogin";

const Main = checkAuth(({ authUser }) => {
  const { list } = useChatroom();
  const { user } = useAuth();

  const hasNewChatroom = !!list.find((room) => room.isNew);

  const location = useGeoLocation();

  useEffect(() => {
    if (hasNewChatroom) {
      toast.success("有新配對，到聊天視窗開始與對方聊天吧");
    }
  }, [hasNewChatroom]);

  useEffect(() => {
    if (user?.id && location.position) {
      updateUserPosition(user.id, location.position);
    }
  }, [user?.id, location.position]);

  return (
    <>
      <NavBar authUser={authUser} />
      <div className="view-wrapper">
        <div className="container">
          <Switch>
            <Route path="/apply">
              <ApplyForm />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/users/:id">
              <UserPreviewModal />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
});

const Routes = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          try {
            const userDocRef = firebase
              .firestore()
              .collection("users")
              .doc(firebaseUser.uid);
            let userDocSnapshot = await userDocRef.get();
            if (!userDocSnapshot.exists) {
              await userDocRef.set({
                id: firebaseUser.uid,
              });
            }

            userDocSnapshot = await userDocRef.get();

            const user = userDocSnapshot.exists
              ? {
                  id: userDocSnapshot.id,
                  ...userDocSnapshot.data(),
                }
              : null;

            dispatch(authSlice.actions.update(user));
          } catch {}
        } else {
          dispatch(authSlice.actions.update(null));
        }
      });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    if (user?.id) {
      updateUserConnectedState(user.id);

      const unsubscribeWatchUser = watchUser(user.id, (user) => {
        dispatch(authSlice.actions.update(user));
      });

      const unsubscribeWatchChatrooms = watchChatrooms(
        user.id,
        (joinedChatrooms) => {
          dispatch(chatroomSlice.actions.set(joinedChatrooms));
        }
      );

      return () => {
        unsubscribeWatchUser();
        unsubscribeWatchChatrooms();
      };
    }
  }, [user?.id, dispatch]);

  return (
    <Router>
      <Switch>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/login/email">
          <EmailLogin />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        {process.env.NODE_ENV === "development" && (
          <Route path="/signup">
            <Signup />
          </Route>
        )}
        <Route path="/admin">
          <Admin />
        </Route>
        <Route
          exact
          path={["/", "/apply", "/profile", "/chatrooms/:id", "/users/:id"]}
        >
          <Main />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
