import firebase from "../../firebase";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { UserCard } from "../tinder/TinderCards";
import { User } from "../../types";
import { useAuth } from "../../redux/slices/auth";

export default function UserPreviewModal() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const auth = useAuth();

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((result) => {
        if (result.exists) {
          setUser(result.data() as User);
        } else {
          alert("找不到該使用者");
          history.goBack();
        }
      })
      .catch(() => {
        alert("發生錯誤，請稍候重試看看");
      });
  }, [id, history]);

  const handleClose = () => {
    history.goBack();
  };

  return user ? (
    <div className="modal user-preview-modal is-medium has-light-bg is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="card wrapper">
          <div className="card-heading">
            <div className="close-wrap">
              <span className="close-modal" onClick={handleClose}>
                <FiX color="#fff" />
              </span>
            </div>
          </div>
          <div className="card-body">
            <UserCard authUser={auth.user} user={user} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
