/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState } from "react";
import firebase from "../firebase";
import Logo from "../assets/img/logo/logo.png";
import _ from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const loginByFacebook = () => {
    setLoading(true);
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        window.location.href = "/";
      })
      .catch((error) => {
        alert(`發生錯誤:${_.get(error, "message", "")}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="signup-wrapper">
      <div className="fake-nav">
        <a href="/" className="logo">
          <img
            className="light-image"
            src={Logo}
            width="112"
            height="28"
            alt=""
            style={{ display: "block" }}
          />
        </a>
      </div>

      <div className="container">
        <div className="login-container is-centered">
          <div className="columns is-vcentered">
            <div className="column">
              <h2 className="form-title has-text-centered">歡迎回來</h2>
              <div className="login-form">
                <form
                  className="form-panel mt-4"
                  onSubmit={handleSubmit((data) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(data.email, data.password)
                      .then((result) => {
                        window.location.href = "/";
                      })
                      .catch((error) => {
                        alert(`發生錯誤:${_.get(error, "message", "")}`);
                      });
                  })}
                >
                  <div className="field">
                    <label>Email</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label>Password</label>
                    <div className="control">
                      <input
                        type="password"
                        className="input"
                        placeholder="Enter your password"
                        {...register("password", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <button
                    className="button is-solid primary-button is-fullwidth raised"
                    type="submit"
                  >
                    登入
                  </button>
                  <div className="account-link has-text-centered">
                    <Link to="/signup">沒有帳號嗎? 註冊</Link>
                  </div>
                </form>

                <div className="buttons" style={{ marginTop: 40 }}>
                  <div
                    className={classNames(
                      "button is-solid primary-button is-fullwidth raised",
                      {
                        "is-loading": loading,
                      }
                    )}
                    onClick={loginByFacebook}
                  >
                    透過 FACEBOOK 登入
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
