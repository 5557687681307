import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { User } from "../../types";
import { RootState } from "../store";

const initialState = {
  isResolved: false,
  user: null as null | User,
};

export default createSlice({
  name: "auth",
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<typeof initialState["user"] | null>
    ) => ({
      ...state,
      isResolved: true,
      user: action.payload,
    }),
  },
});

export const useAuth = () => useSelector((state: RootState) => state.auth);
