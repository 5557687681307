import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import { User, Chatroom } from "../types";
import faker from "faker";
import dayjs from "dayjs";

const firebaseConfig = {
  apiKey: "AIzaSyCzQoOlaeeLGZd5LNjyg9F7yGA2g4PZx8g",
  authDomain: "idoctor-f5167.firebaseapp.com",
  projectId: "idoctor-f5167",
  storageBucket: "idoctor-f5167.appspot.com",
  messagingSenderId: "515917944352",
  appId: "1:515917944352:web:c3907ed0c3b9466d6d8e45",
  measurementId: "G-SQ8E6Y1MJL",
  databaseURL:
    "https://idoctor-f5167-default-rtdb.asia-southeast1.firebasedatabase.app",
};

export const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export { firebase as default };

export const updateUserConnectedState = (userId: string) => {
  firebase
    .database()
    .ref(".info/connected")
    .on("value", (snapshot) => {
      const value = snapshot.val();
      const ref = firebase.database().ref(`/status/${userId}`);
      if (value) {
        ref
          .onDisconnect()
          .set({
            value: false,
            lastChanged: firebase.database.ServerValue.TIMESTAMP,
          })
          .then(() => {
            ref.set({
              value: true,
              lastChanged: firebase.database.ServerValue.TIMESTAMP,
            });
          });
      } else {
        ref.set({
          value: false,
          lastChanged: firebase.database.ServerValue.TIMESTAMP,
        });
      }
    });
};

export const watchUser = (
  userId: string,
  onChange: (user: User | null) => void
) =>
  firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .onSnapshot((snapshot) =>
      onChange(
        snapshot.exists
          ? ({
              id: snapshot.id,
              ...snapshot.data(),
            } as User)
          : null
      )
    );

export const watchChatrooms = (
  userId: string,
  onChange: (chatrooms: Chatroom[]) => void
) =>
  firebase
    .firestore()
    .collection("chatrooms")
    .where(`userIds.${userId}`, "==", true)
    .onSnapshot(async (snapshot) => {
      const list = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Chatroom[];

      onChange(list);
    });

export const uploadImage = (image: object | string, path: string) => {
  return new Promise<{ path: string; url: string }>((resolve, reject) => {
    const ref = firebase.storage().ref(path);

    const uploadTask =
      // @ts-ignore
      typeof image === "object" ? ref.put(image) : ref.putString(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        reject(error);
      },
      () => {
        ref.getDownloadURL().then((url) => {
          resolve({
            path,
            url,
          });
        });
      }
    );
  });
};

export const deleteImage = async (path: string) => {
  try {
    await firebase.storage().ref(path).delete();
  } catch {}
};

export const signUpByEmailAndPassword = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const signInWithEmailAndPassword = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const createFakeUsers = async (count: number) => {
  for (let i = 0; i < count; i++) {
    const email = faker.internet.email();
    const password = faker.internet.password();
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    if (result.user?.uid) {
      await firebase
        .firestore()
        .collection("users")
        .doc(result.user?.uid)
        .set({
          isFaked: true,
          email,
          password,
          role: "admin",
          avatar: faker.image.avatar(),
          application: {
            verifyStatus: 1,
          },
          profile: {
            birthday: dayjs()
              .subtract(Math.floor(Math.random() * 20) + 20, "years")
              .subtract(Math.floor(Math.random() * 200), "days")
              .format("YYYY-MM-DD"),
            description: faker.lorem.paragraph(),
            desireToGetMarried: Math.floor(Math.random() * 10),
            gender: Math.floor(Math.random() * 3),
            lineId: "",
            maritalStatus: Math.floor(Math.random() * 2),
            nickname: faker.name.findName(),
            targetJob: Math.floor(Math.random() * 2),
            targetType: Math.floor(Math.random() * 2),
          },
        });
    }
  }
};

export const updateUserPosition = (
  userId: string,
  position: GeolocationPosition
) => {
  firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .update({
      position: {
        timestamp: position.timestamp,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
    });
};
