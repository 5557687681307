import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import { Redirect } from "react-router-dom";
import firebase, { deleteImage, uploadImage } from "../firebase";
import { checkAuth } from "../helpers/auth";
import UploadImageModal from "../partials/modal/UploadImageModal";
import { User } from "../types";

const BlackUserRow = ({
  authUser,
  userId,
}: {
  authUser: User;
  userId: string;
}) => {
  const [user, setUser] = useState<null | User>(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          setUser(snapshot.data() as User);
        }
      });
  }, [userId]);

  return user ? (
    <div className="card mb-2">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              {user.photos && user.photos.length > 0 ? (
                <img src={user.photos[0].url} alt="black-user-avatar" />
              ) : null}
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-6 mt-2">{user.profile?.nickname}</p>
          </div>
          <div className="media-right ">
            <div
              className="button is-link is-light"
              onClick={async () => {
                if (window.confirm("確定要將對方移除你的封鎖名單嗎?")) {
                  try {
                    await firebase
                      .firestore()
                      .collection("users")
                      .doc(authUser.id)
                      .update({
                        blackList: firebase.firestore.FieldValue.arrayRemove(
                          user.id
                        ),
                      });
                  } catch {}
                }
              }}
            >
              移除
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

type Props = {
  authUser: User;
};

const Profile = ({ authUser }: Props) => {
  const { profile } = authUser;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nickname: profile?.nickname,
      description: profile?.description,
      gender: profile?.gender,
      preferGender: profile?.preferGender,
      birthday: profile?.birthday,
      maritalStatus: profile?.maritalStatus,
      desireToGetMarried: profile?.desireToGetMarried,
      targetJob: profile?.targetJob,
      targetType: profile?.targetType,
      lineId: profile?.lineId,
    },
  });
  const [shouldRedirect, setShouldRedirect] = useState(false);
  // const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateProfile = useCallback(
    (
      uid: string,
      properties: {
        [x: string]: any;
      }
    ) => {
      setLoading(true);
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update({
          profile: {
            ...profile,
            ...properties,
            desireToGetMarried: Number(properties.desireToGetMarried),
            gender: Number(properties.gender),
            preferGender: Number(properties.preferGender),
            maritalStatus: Number(properties.maritalStatus),
            targetJob: Number(properties.targetJob),
            targetType: Number(properties.targetType),
          },
        })
        .then(() => {
          if (!profile) {
            setShouldRedirect(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [profile]
  );

  if (shouldRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="settings-wrapper is-full">
      <div id="general-settings" className="settings-section is-active">
        <div className="settings-panel border-none">
          <div className="settings-form mx-auto">
            <div className="columns is-multiline ">
              <div className="column is-12 ">
                {(!authUser.photos || authUser.photos.length === 0) && (
                  <div className="notification">
                    請先上傳照片，才能讓別人看見你
                  </div>
                )}
                {!authUser.profile && (
                  <div className="notification">
                    請先填寫表單才能幫你找到適合的對象喔
                  </div>
                )}

                {/* <div className="field field-group border-none">
                  <label>頭像</label>
                  {!!authUser.avatar && (
                    <div>
                      <img
                        className="circle-avatar"
                        src={authUser.avatar ?? ""}
                        alt="avatar"
                      />
                    </div>
                  )}
                  <div>
                    <button
                      className="button is-solid mb-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setModal1Visible(true);
                      }}
                    >
                      上傳照片
                    </button>
                  </div>
                </div>

                <UploadImageModal
                  visible={modal1Visible}
                  onRequestClose={() => setModal1Visible(false)}
                  aspect={1}
                  onUploadImage={async (image) => {
                    const item = await uploadImage(
                      image,
                      `users/${authUser.id}/avatar`
                    );
                    await firebase
                      .firestore()
                      .collection("users")
                      .doc(authUser.id)
                      .update({
                        avatar: item.url,
                      });
                  }}
                /> */}

                <div className="field field-group border-none">
                  <label>上傳照片(最多10張)</label>

                  <div className="mb-2">
                    <button
                      disabled={(authUser.photos ?? []).length >= 10}
                      className="button is-solid"
                      onClick={() => setModal2Visible(true)}
                    >
                      選擇照片
                    </button>
                    <UploadImageModal
                      visible={modal2Visible}
                      onRequestClose={() => setModal2Visible(false)}
                      aspect={1}
                      onUploadImage={async (image) => {
                        const item = await uploadImage(
                          image,
                          `users/${authUser.id}/photos/${nanoid()}`
                        );
                        await firebase
                          .firestore()
                          .collection("users")
                          .doc(authUser.id)
                          .update({
                            photos:
                              firebase.firestore.FieldValue.arrayUnion(item),
                          });
                      }}
                    />
                  </div>

                  <div className="columns is-multiline">
                    {(authUser.photos ?? []).map((item) => {
                      return (
                        <div key={item.url} className="column is-4">
                          <div className="card post-feed-item">
                            <div className="card-image">
                              <figure className="image is-1by1">
                                <img src={item.url} alt="me" />
                              </figure>
                            </div>
                            <button
                              className="button is-solid is-fullwidth mt-2"
                              onClick={async () => {
                                if (window.confirm("確定要刪除嗎")) {
                                  await firebase
                                    .firestore()
                                    .collection("users")
                                    .doc(authUser.id)
                                    .update({
                                      photos:
                                        firebase.firestore.FieldValue.arrayRemove(
                                          item
                                        ),
                                    });
                                  await deleteImage(item.path);
                                }
                              }}
                            >
                              刪除
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <form
              onSubmit={handleSubmit((data) => {
                updateProfile(authUser.id, data);
              })}
            >
              <div>
                <div className="field field-group">
                  <label>暱稱</label>
                  {errors.nickname && (
                    <div className="form-error">
                      {errors.nickname.type === "required" && (
                        <p className="help is-danger">必填</p>
                      )}
                    </div>
                  )}
                  <div className="control has-icon">
                    <input
                      type="text"
                      className="input is-fade"
                      placeholder="您希望在「醫戀」App上大家怎麼稱呼您呢？"
                      {...register("nickname", {
                        required: true,
                      })}
                    />
                    <div className="form-icon">
                      <FiUser />
                    </div>
                  </div>
                </div>

                <div className="field field-group">
                  <label>寫一段話向朋友介紹你自己</label>
                  {errors.description && (
                    <div className="form-error">
                      {errors.description.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control has-icon">
                    <textarea
                      className="textarea is-fade"
                      {...register("description", {
                        required: true,
                      })}
                    />
                  </div>
                </div>

                <div className="field field-group border-none">
                  <label>性別</label>
                  {errors.gender && (
                    <div className="form-error">
                      {errors.gender.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <div className="select">
                      <select
                        {...register("gender", {
                          required: true,
                        })}
                      >
                        <option value="0">男</option>
                        <option value="1">女</option>
                        <option value="2">第三性</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field field-group border-none">
                  <label>想要認識的性別</label>
                  {errors.preferGender && (
                    <div className="form-error">
                      {errors.preferGender.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <div className="select">
                      <select
                        {...register("preferGender", {
                          required: true,
                        })}
                      >
                        <option value="0">男性</option>
                        <option value="1">女性</option>
                        <option value="2">不拘</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field field-group">
                  <label>生日</label>
                  {errors.birthday && (
                    <div className="form-error">
                      {errors.birthday.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <input
                      className="input"
                      type="date"
                      {...register("birthday", {
                        required: true,
                      })}
                    />
                  </div>
                </div>

                <div className="field field-group border-none">
                  <label>婚姻狀態</label>
                  {errors.maritalStatus && (
                    <div className="form-error">
                      {errors.maritalStatus.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <div className="select">
                      <select
                        {...register("maritalStatus", {
                          required: true,
                        })}
                      >
                        <option value="1">未婚且單身</option>
                        <option value="0">其他</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field field-group">
                  <label>渴望結婚的程度(0~10)</label>
                  {errors.desireToGetMarried && (
                    <div className="form-error">
                      {errors.desireToGetMarried.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                      {errors.desireToGetMarried.type === "min" && (
                        <span className="help is-danger">請填寫0~10</span>
                      )}
                      {errors.desireToGetMarried.type === "max" && (
                        <span className="help is-danger">請填寫0~10</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      {...register("desireToGetMarried", {
                        required: true,
                        min: 0,
                        max: 10,
                      })}
                    />
                  </div>
                </div>

                <div className="field field-group border-none">
                  <label>您想認識的對象是?</label>
                  {errors.targetJob && (
                    <div className="form-error">
                      {errors.targetJob.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <div className="select">
                      <select
                        {...register("targetJob", {
                          required: true,
                        })}
                      >
                        <option value="1">醫師</option>
                        <option value="0">不拘</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field field-group border-none">
                  <label>為了找到合適的朋友，請問你所希冀的朋友類型是?</label>
                  {errors.targetType && (
                    <div className="form-error">
                      {errors.targetType.type === "required" && (
                        <span className="help is-danger">必填</span>
                      )}
                    </div>
                  )}
                  <div className="control">
                    <div className="select">
                      <select
                        {...register("targetType", {
                          required: true,
                        })}
                      >
                        <option value="1">以結婚為提前的交往</option>
                        <option value="0">不設限</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field field-group">
                  <label>
                    為了能即時得到您對本App封測的回饋意見，希望能知道您的Line
                    ID以利跟您聯絡。（選擇性）
                  </label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      {...register("lineId", {})}
                    />
                  </div>
                </div>
              </div>

              <button
                className={`mt-4 button is-link ${loading ? "is-loading" : ""}`}
                type="submit"
              >
                更新個人檔案
              </button>
            </form>

            <hr />

            <div className="field field-group border-none">
              <label>黑名單</label>
              <div>
                {(authUser.blackList ?? []).map((userId) => (
                  <BlackUserRow
                    authUser={authUser}
                    userId={userId}
                    key={userId}
                  />
                ))}

                {!authUser.blackList?.length && (
                  <article className="message is-primary p-4">
                    <span className="is-size-6 has-text-grey">無</span>
                  </article>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default checkAuth(Profile);
