export const convertVerifyStatus = (verifyStatus: number | undefined) => {
  switch (verifyStatus) {
    case -1:
      return "已拒絕";
    case 0:
      return "審核中";
    case 1:
      return "已通過";
    default:
      return "未定義";
  }
};

export const convertGender = (gender: number | undefined) => {
  switch (gender) {
    case 0:
      return "男性";
    case 1:
      return "女性";
    case 2:
      return "第三性";
    default:
      return "";
  }
};
