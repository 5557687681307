import React from "react";
import Routes from "./Routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer transition={Slide} hideProgressBar={true} />
    </Provider>
  );
}

export default App;
