import {
  applyMiddleware,
  combineReducers,
  createStore,
} from "@reduxjs/toolkit";

import auth from "./slices/auth";
import chatroom from "./slices/chatroom";

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");
  const logger = createLogger({
    diff: true,
    collapsed: true,
  });

  middlewares.push(logger);
}

const store = createStore(
  combineReducers({
    auth: auth.reducer,
    chatroom: chatroom.reducer,
  }),
  applyMiddleware(...middlewares)
);

export default store;

export type RootState = ReturnType<typeof store.getState>;
