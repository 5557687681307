import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { Chatroom, Message, User } from "../../types";
import { RootState } from "../store";
import firebase from "../../firebase";
import _ from "lodash";

const initialState = {
  isResolved: false,
  list: [] as Chatroom[],
  selectedId: null as null | string,
};

export default createSlice({
  name: "chatroom",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<typeof initialState["list"]>) => ({
      ...state,
      isResolved: true,
      list: action.payload.map((item) => {
        const origin = state.list.find((o) => o.id === item.id);

        if (origin) {
          return {
            ...origin,
            ...item,
          };
        }
        return item;
      }),
    }),
    enter: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedId: action.payload,
    }),
    leave: (state) => ({
      ...state,
      selectedId: null,
    }),
    setUser: (
      state,
      action: PayloadAction<{
        chatroomId: string;
        user: User;
      }>
    ) => {
      state.list.map((item) => {
        const {
          payload: { chatroomId, user },
        } = action;

        if (item.id === chatroomId) {
          if (!item.users) {
            item.users = [] as User[];
          }

          const index = item.users.findIndex((o) => o.id === user.id);
          if (index > -1) {
            item.users[index] = user;
          } else {
            item.users.push(user);
          }

          return item;
        }

        return item;
      });

      return state;
    },
    updateMessages: (
      state,
      action: PayloadAction<{
        chatroomId: string;
        changes: firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];
      }>
    ) => {
      state.list.map((item) => {
        const {
          payload: { chatroomId, changes },
        } = action;

        if (item.id === chatroomId) {
          changes.forEach((change) => {
            if (
              change.type === "added" &&
              !item.messages?.find((m) => m.id === change.doc.id)
            ) {
              const message = {
                id: change.doc.id,
                ...change.doc.data(),
              } as Message;

              if (!item.messages) {
                item.messages = [message];
              } else {
                item.messages.splice(
                  _.sortedIndexBy(item.messages, message, "id"),
                  0,
                  message
                );
              }
            } else if (change.type === "removed") {
              item.messages = item.messages?.filter(
                (m) => m.id !== change.doc.id
              );
            }
          });

          return item;
        }

        return item;
      });

      return state;
    },
  },
});

export const useChatroom = () =>
  useSelector((state: RootState) => {
    return {
      ...state.chatroom,
      list: state.chatroom.list
        .filter((room) => {
          return !state.auth.user?.blackList?.find((blackUserId) => {
            return Object.keys(room.userIds).includes(blackUserId);
          });
        })
        .map((room) => {
          let hasUnread = false;
          let isNew = false;
          const authUserId = state.auth.user?.id;
          if (!room.readedAt || (authUserId && !room.readedAt[authUserId])) {
            isNew = true;
          }
          if (room.messages && authUserId) {
            const readedAt = room.readedAt ? room.readedAt[authUserId] : null;
            for (let i = room.messages.length - 1; i >= 0; i--) {
              const message = room.messages[i];
              if (
                message.userId !== authUserId &&
                (!readedAt || Number(message.id) > readedAt)
              ) {
                hasUnread = true;
                break;
              }
            }
          }
          return {
            ...room,
            hasUnread,
            isNew,
          };
        }),
    };
  });

export const useSelectedChatroom = () =>
  useSelector((state: RootState) => {
    if (state.chatroom.selectedId) {
      return (
        state.chatroom.list.find(
          (item) => item.id === state.chatroom.selectedId
        ) ?? null
      );
    }

    return null;
  });
