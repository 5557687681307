import React from "react";
import classNames from "classnames";
import useComponentVisible from "../../hooks/useComponentVisible";

type Props = {
  renderIcon: () => JSX.Element;
  children: React.ReactChild;
  dropdownClassNames?: string;
  showIndicator?: boolean;
};

const NavItem = ({
  renderIcon,
  children,
  dropdownClassNames,
  showIndicator,
}: Props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className="navbar-item is-icon drop-trigger cursor-pointer">
      <div
        ref={ref}
        className={classNames("icon-link", {
          "is-active": isComponentVisible,
        })}
        onClick={() => {
          setIsComponentVisible((v: boolean) => !v);
        }}
      >
        {renderIcon()}
        {showIndicator && <span className="indicator"></span>}
      </div>

      <div
        className={classNames(`nav-drop ${dropdownClassNames ?? ""}`, {
          "is-active": isComponentVisible,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default NavItem;
