import React from "react";
import { checkAuth } from "../helpers/auth";
import { User } from "../types";
import TinderCards from "../partials/tinder/TinderCards";

type Props = {
  authUser: User;
};

const Home = ({ authUser }: Props) => {
  return (
    <>
      <TinderCards authUser={authUser} />
    </>
  );
};

export default checkAuth(Home);
