import React from "react";
import { Link } from "react-router-dom";
import Placeholder from "../assets/img/illustrations/placeholders/3.svg";

const Error404 = () => {
  return (
    <div className="error-container">
      <div className="error-wrapper">
        <div className="error-inner has-text-centered">
          <div className="bg-number dark-inverted">404</div>
          <img className="light-image" src={Placeholder} alt="placeholder" />
          <h3 className="dark-inverted">找不到此頁面</h3>
          <p></p>
          <div className="button-wrap">
            <Link
              className="button h-button is-normal is-primary is-elevated"
              to="/"
            >
              回首頁
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
